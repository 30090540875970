import { Box, Button, FormControl, TextField, Typography } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import { Appearance, StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { AdoptersClient } from '../../../interactors/clients/AdoptersClient'
import { backendClient } from '../../../interactors/clients/client'
import { AdopterDto } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { CheckoutForm } from '../../common/StripeCheckoutForm'

interface FormParams {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  city: string
  address: string
  postalCode: string
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string)

export const FelomeMainContent: React.FC = () => {
  const [step, setStep] = React.useState(1)
  const [clientSecret, setClientSecret] = useState<string>('')

  const accountsStore = useAccountsStore()
  const currentAccount = accountsStore.connectedAccount

  const getDefaultValues = (account: AdopterDto | null): FormParams => ({
    firstName: account?.firstName || '',
    lastName: account?.lastName || '',
    address: account?.address || '',
    postalCode: account?.postalCode || '',
    email: account?.email || '',
    phoneNumber: account?.phoneNumber || '',
    city: account?.city || '',
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty },
  } = useFormExtended(currentAccount, getDefaultValues)

  const createPaymentIntent = async () => {
    const response = await backendClient.post('/adopters-platform/stripe/create-payment-intent', {
      items: [{ reference: 'felome', quantity: 1, price: 15000 }],
    })
    setClientSecret(response.data)
  }

  const onSubmit = async (data: FormParams) => {
    if (!currentAccount) return
    if (isDirty) {
      const updatedAccount = {
        ...currentAccount,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        address: data.address,
        city: data.city,
        postalCode: data.postalCode,
      }
      await AdoptersClient.editAccount(updatedAccount)
    }

    createPaymentIntent()
  }

  const appearance: Appearance = {
    theme: 'stripe',
  }

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  }

  return clientSecret ? (
    <Elements options={options} stripe={stripePromise}>
      <CheckoutForm price={150} />
    </Elements>
  ) : step < 2 ? (
    <>
      <Box
        component="img"
        src="https://i.imgur.com/M4Sg75x.jpeg"
        alt={`Illustration de Felome`}
        sx={{
          objectFit: 'contain',
          maxWidth: '100%',
          maxHeight: '400px', // make image take full height of the container
          imageRendering: '-webkit-optimize-contrast',
        }}
      />
      <Button variant="contained" color="secondary" onClick={() => setStep((prev) => prev + 1)}>
        Je commande un test !
      </Button>
    </>
  ) : (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: '10px', border: 'solid 2px', borderRadius: '10px', p: 2 }}
    >
      <Typography sx={{ fontWeight: 700 }}>Vérifiez vos informations personnelles</Typography>

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <TextField label="Prénom" variant="outlined" {...register('firstName')} placeholder="Paul" />
        <TextField label="Nom" variant="outlined" {...register('lastName')} placeholder="Lelong" />
        <TextField
          label="Email"
          variant="outlined"
          {...register('email')}
          placeholder="paul.lelong@gmail.com"
          disabled
        />
        <FormControl variant="outlined" sx={{ width: '100%' }} required>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              validate: (value: string | undefined) => {
                console.log(value)
                return !value || value.trim() === '' || matchIsValidTel(value) || value.trim() === '+33'
                  ? true
                  : 'Tel is invalid'
              },
            }}
            render={({ field, fieldState }) => (
              <MuiTelInput
                {...field}
                aria-label="Numero de Téléphone"
                label="Téléphone"
                defaultCountry="FR"
                helperText={fieldState.error ? "Le numero de téléphone n'est pas valide" : ''}
                error={!!fieldState.error}
                required
              />
            )}
          />
        </FormControl>
        <TextField label="Adresse" variant="outlined" {...register('address')} placeholder="12, impasse de la loutre" />
        <TextField label="Code Postal" variant="outlined" {...register('postalCode')} placeholder="34210" />
        <TextField label="Ville" variant="outlined" {...register('city')} placeholder="Aigues-Vives" />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '12px', mt: 1 }}>
          <Button variant="contained" color="primary" type="button" onClick={() => setStep((prev) => prev - 1)}>
            Précédent
          </Button>
          <Button variant="contained" color="secondary" type="submit">
            Commander & Payer
          </Button>
        </Box>
      </form>
    </Box>
  )
}
