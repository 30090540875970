import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { AnimalProfileDto } from '../../interactors/gen/backendClient'
import { CircularProgressPanel } from '../common/CircularProgressPanel'
import { AnimalCard } from './AnimalCard'
import { AnimalHealthOperationsTab } from '../Animals/AnimalHealthOperationsTab'
import { FurtherInfo } from './FurtherInfo'
import { Documents } from './Documents'

export const AnimalDetails: React.FC = () => {
  const [animal, setAnimal] = useState<AnimalProfileDto | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams() as { id: string }

  useEffect(() => {
    const fetchAnimal = async () => {
      const animal = await AnimalProfilesClient.getAnimalByMongoId(id)
      setAnimal(animal)
      setIsLoading(false)
    }
    fetchAnimal()
  }, [id])

  if (!animal || isLoading) {
    return <CircularProgressPanel />
  }

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20, overflowY: 'auto' }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginY: 1, p: 4 }}>
        <Grid container spacing={3} sx={{ pb: 4 }}>
          <Grid item xs={12} md={3.5}>
            <AnimalCard animal={animal} />
            <FurtherInfo animal={animal} />
            <Documents animal={animal} />
          </Grid>
          <Grid item xs={12} md={8.5}>
            <AnimalHealthOperationsTab animal={animal} displayOnly />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
