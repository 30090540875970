import React from 'react'
import { AdoptionAttemptDto } from '../../interactors/gen/backendClient'
import { AdoptionOptionalStep } from './AdoptionOptionalStep'
import { Box } from '@mui/material'
import { OptionalStepPopUp } from './OptionalStepPopUp'
import dayjs from 'dayjs'

interface Props {
  attempt: AdoptionAttemptDto
  animalName: string
  updateMutation: (data: { adoptionAttempt: AdoptionAttemptDto; id: string }) => void
}

export enum AdoptionOptionalStepType {
  NewName = 'new-name',
  Family = 'family',
  HealthCare = 'health-care',
  MedicalAppointment = 'medical-appointment',
  Products = 'products',
}

export const AdoptionOptionalSteps: React.FC<Props> = ({ attempt, animalName, updateMutation }) => {
  const [isPopUpOpen, setIsPopUpOpen] = React.useState(false)
  const [selectedStep, setSelectedStep] = React.useState<AdoptionOptionalStepType | null>(null)

  const getValueForStep = (step: AdoptionOptionalStepType): boolean | null => {
    switch (step) {
      case AdoptionOptionalStepType.NewName:
        return attempt.petHasNewName !== undefined ? (attempt.petHasNewName as boolean) : null
      case AdoptionOptionalStepType.Family:
        return attempt.petHasNewFamily !== undefined ? (attempt.petHasNewFamily as boolean) : null
      case AdoptionOptionalStepType.HealthCare:
        return attempt.petIsInsured !== undefined ? (attempt.petIsInsured as boolean) : null
      case AdoptionOptionalStepType.MedicalAppointment:
        return attempt.petHasAppointment !== undefined ? (attempt.petHasAppointment as boolean) : null
      case AdoptionOptionalStepType.Products:
        return attempt.petDeservesToBeSpoiled !== undefined ? (attempt.petDeservesToBeSpoiled as boolean) : null
      default:
        return null
    }
  }

  const onSubmit = ({
    step,
    value,
    validate,
    data,
  }: {
    step: AdoptionOptionalStepType
    value: boolean
    validate?: boolean
    data?: string
  }) => {
    console.log(step, value, validate)
    if (!attempt) return

    const newAttempt = { ...attempt }

    switch (step) {
      case AdoptionOptionalStepType.NewName:
        if (value === true && !validate) {
          setSelectedStep(step)
          setIsPopUpOpen(true)
          return
        }
        newAttempt.petHasNewName = value
        newAttempt.newName = value ? data : undefined
        break
      case AdoptionOptionalStepType.Family:
        if (value === true && !validate) {
          setSelectedStep(step)
          setIsPopUpOpen(true)
          return
        }
        newAttempt.petHasNewFamily = value
        break
      case AdoptionOptionalStepType.HealthCare:
        newAttempt.petIsInsured = value
        break
      case AdoptionOptionalStepType.MedicalAppointment:
        if (value === true && !validate) {
          setSelectedStep(step)
          setIsPopUpOpen(true)
          return
        }
        newAttempt.petHasAppointment = value
        newAttempt.appointmentDate = value ? data : undefined
        break
      case AdoptionOptionalStepType.Products:
        newAttempt.petDeservesToBeSpoiled = value
        break

      default:
        break
    }

    if (getValueForStep(step) === value && !data) return

    updateMutation({ adoptionAttempt: newAttempt, id: attempt.id })
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {Object.values(AdoptionOptionalStepType).map((step) => (
        <AdoptionOptionalStep
          key={step}
          step={step}
          animalName={animalName}
          onSubmit={onSubmit}
          stepValue={getValueForStep(step)}
          newAnimalName={attempt.newName}
          operationDate={attempt.appointmentDate ? dayjs(attempt.appointmentDate).format('DD/MM/YYYY') : undefined}
        />
      ))}
      <OptionalStepPopUp
        isOpen={isPopUpOpen}
        onClose={() => setIsPopUpOpen(false)}
        step={selectedStep}
        onSubmit={onSubmit}
      />
    </Box>
  )
}
