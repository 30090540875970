import { CircularProgress, Box } from '@mui/material'
import { CSSProperties, FC } from 'react'

export type Props = {
  processing?: boolean
  padding?: boolean
  alignItems?: CSSProperties['alignItems']
}

export const CircularProgressPanel: FC<Props> = ({ processing = true, padding = true, alignItems = 'flex-start' }) => {
  return processing ? (
    <Box
      sx={{
        background: 'white',
        position: 'absolute',
        zIndex: 100,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems,
        justifyContent: 'center',
        paddingTop: padding ? 10 : 0,
      }}
    >
      <CircularProgress />
    </Box>
  ) : null
}
