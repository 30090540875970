import React from 'react'
import { AnimalDtoSpecies, AnimalProfileDto } from '../../interactors/gen/backendClient'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { Modal, Box, TextField, Button, IconButton } from '@mui/material'
import { Field } from '../common/FormField'
import { ControlledToggleButton } from '../common/ControlledToggleButton'
import { LoadingButton } from '@mui/lab'
import { getReadableSpecies } from '../../domain/Animal/AnimalDisplay'
import { ControlledSelectField } from '../common/ControlledSelectField'
import { Edit } from '@mui/icons-material'
import { theme } from '../theme'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { ControlledDateField } from '../common/ControlledDateField'
import { isValidPastDateCoherence } from '../../utils/date/isValidPastDateCoherence'
import dayjs from 'dayjs'

interface Props {
  animal: AnimalProfileDto
  setAnimal: (animal: AnimalProfileDto) => void
}

interface FormData {
  name: string
  sex?: AnimalProfileDto['sex']
  species: AnimalProfileDto['species']
  birthday: string
}

export const EditAnimalGeneralInfoModal: React.FC<Props> = ({ animal, setAnimal }) => {
  const [isModalOpened, setIsModalOpened] = React.useState(false)

  const onCancel = () => {
    setIsModalOpened(false)
  }

  const onSubmit = async (data: FormData) => {
    animal.name = data.name
    animal.sex = data.sex
    animal.species = data.species
    animal.birthday = {
      date: data.birthday ? dayjs(data.birthday).format('DD/MM/YYYY') : animal.birthday?.date || '',
      isApproximate: animal.birthday?.isApproximate,
    }

    const newAnimal = await AnimalProfilesClient.editAnimalProfile(animal)

    setAnimal(newAnimal)

    setIsModalOpened(false)
  }

  const getDefaultValues = (animal: AnimalProfileDto): FormData => ({
    name: animal.name,
    sex: animal.sex,
    species: animal.species,
    birthday: animal.birthday?.date || '',
  })

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useFormExtended(animal, getDefaultValues)

  return (
    <>
      <IconButton
        onClick={() => setIsModalOpened(true)}
        sx={{ border: '1px solid', backgroundColor: theme.palette.primary.main, color: 'white' }}
      >
        <Edit />
      </IconButton>
      <Modal open={isModalOpened} onClose={onCancel}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              maxWidth: '100%',
              width: '400px',
              maxHeight: '100%',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
              padding: '24px',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              overflowY: 'auto',
            }}
          >
            <Field label="Nom" required>
              <TextField
                aria-label="Nom"
                type="text"
                placeholder="Médor"
                {...register('name', { required: 'Le nom est requis' })}
                error={!!errors.name}
                helperText={errors.name?.message}
                required
                fullWidth
                hiddenLabel
              />
            </Field>
            <Field label="Sexe" required>
              <ControlledToggleButton<FormData>
                control={control}
                fieldName="sex"
                error={errors.sex}
                requiredRule="Vous devez indiquer le sexe de l'animal"
                firstOptionLabel="Mâle"
                secondOptionLabel="Femelle"
                firstOptionValue={'male'}
                secondOptionValue={'female'}
                fullWidth
              />
            </Field>
            <Field label="Espèce" required>
              <ControlledSelectField
                control={control}
                fieldName="species"
                error={errors.species}
                requiredRule="Vous devez définir l'espèce de l'animal"
                options={Object.values(AnimalDtoSpecies).map((status: AnimalDtoSpecies) => ({
                  label: getReadableSpecies(status, { withEmoji: true }),
                  value: status,
                }))}
                size="small"
              />
            </Field>
            <Field label="Date de naissance">
              <ControlledDateField
                control={control}
                fieldName="birthday"
                error={errors.birthday}
                requiredRule={undefined}
                validate={(value) => isValidPastDateCoherence(value) || value === null}
                size="small"
                label="Date de naissance"
              />
            </Field>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: { xs: '10px', md: '24px' },
              }}
            >
              <Button variant="text" onClick={onCancel} sx={{ fontSize: '18px' }}>
                Annuler
              </Button>
              <LoadingButton type="submit" variant="contained" loading={false} sx={{ fontSize: '18px' }}>
                Enregistrer
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  )
}
