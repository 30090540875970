export const PATHS = {
  profil: { absolute: '/profil', relative: 'profil' },
  monProfil: { absolute: '/profil/mon-profil', relative: 'mon-profil' },
  formulaire: { absolute: '/profil/formulaire-de-pre-adoption', relative: 'formulaire-de-pre-adoption' },

  adoptions: { absolute: '/adoptions', relative: 'adoptions' },
  adoptionDetails: { absolute: '/adoptions/:adoptionId', relative: ':adoptionId' },
  adoptionWelcomePack: { absolute: '/adoptions/:animalId/welcome-pack', relative: ':animalId/welcome-pack' },

  animaux: { absolute: '/mes-animaux', relative: 'mes-animaux' },
  ajouterAnimal: { absolute: '/mes-animaux/ajouter-un-animal', relative: 'ajouter-un-animal' },
  animalDetails: { absolute: '/mes-animaux/:animalId', relative: ':animalId' },
  ajouterOperation: {
    absolute: '/mes-animaux/:animalId/ajouter-une-operation',
    relative: ':animalId/ajouter-une-operation',
  },
  animalOperationDetails: {
    absolute: '/mes-animaux/:animalId/operation/:operationId',
    relative: ':animalId/operation/:operationId',
  },
  ajouterObservation: {
    absolute: '/mes-animaux/:animalId/ajouter-une-observation',
    relative: ':animalId/ajouter-une-observation',
  },
  animalObservationDetails: {
    absolute: '/mes-animaux/:animalId/observation/:observationId',
    relative: ':animalId/observation/:observationId',
  },
  ajouterDocument: {
    absolute: '/mes-animaux/:animalId/ajouter-un-document',
    relative: ':animalId/ajouter-un-document',
  },

  chat: {
    absolute: '/chat',
    relative: 'chat',
  },

  actualites: {
    absolute: '/actualites',
    relative: 'actualites',
  },
  ajouterActualite: {
    absolute: '/actualites/ajouter-une-actualite',
    relative: 'ajouter-une-actualite',
  },

  produits: {
    absolute: '/produits',
    relative: 'produits',
  },
  produitDetails: {
    absolute: '/produits/:reference',
    relative: ':reference',
  },

  panier: {
    absolute: '/panier',
    relative: 'panier',
  },

  'pet-parents': {
    absolute: '/pet-parents',
    relative: 'pet-parents',
  },

  services: {
    absolute: '/services',
    relative: 'services',
  },
  servicesDetails: {
    absolute: '/services/:id',
    relative: ':id',
  },

  animalWelcomePack: { absolute: '/mes-animaux/:animalId/welcome-pack', relative: ':animalId/welcome-pack' },

  partenaires: { absolute: '/partenaires', relative: 'partenaires' },
  partenaireDetails: { absolute: '/partenaires/:brandName', relative: ':brandName' },

  login: { absolute: '/login', relative: 'login' },

  createPassword: { absolute: '/create-password', relative: 'create-password' },

  resetPassword: { absolute: '/reset-password', relative: 'reset-password' },
  requestPasswordReset: { absolute: '/request-password-reset', relative: 'request-password-reset' },

  suivi: { absolute: '/suivi', relative: 'suivi' },
  suiviAnimal: { absolute: '/suivi/:id', relative: ':id' },
  suiviOperationDetails: {
    absolute: '/suivi/:id/operation/:operationId',
    relative: ':id/operation/:operationId',
  },
  suiviObservationDetails: {
    absolute: '/suivi/:id/observation/:observationId',
    relative: ':id/observation/:observationId',
  },
}
