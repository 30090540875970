import { AdopterDto } from '../interactors/gen/backendClient'
import { PATHS } from '../views/PATHS'

export const findHome = (member: AdopterDto | null) => {
  if (member?.adoptionAttempts && member.adoptionAttempts.length > 0) {
    if (member.adoptionAttempts.some((adoption) => adoption.status === 'done' && adoption.step >= 6)) {
      return PATHS.animaux.absolute
    } else {
      return PATHS.adoptions.absolute
    }
  }
  return PATHS.monProfil.absolute
}
