import { Box, Typography } from '@mui/material'
import React from 'react'
import { AdoptionAddNewItem } from './AdoptionAddNewItem'
import { useAccountsStore } from '../../store/AccountsStore'
import { AdoptionItem } from './AdoptionItem'

export const AdoptionsListScreen: React.FC = () => {
  const accountsStore = useAccountsStore()
  const adoptions = accountsStore.connectedAccount?.adoptionAttempts
  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginY: 1, p: 4, pb: 12 }}>
        <Typography variant="h1">Mes demandes d&#39;adoptions</Typography>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {adoptions ? (
            adoptions.map((adoption) => <AdoptionItem key={adoption.id} adoption={adoption} />)
          ) : (
            <AdoptionAddNewItem />
          )}
        </Box>
      </Box>
    </Box>
  )
}
