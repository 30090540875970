import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { AnimalProfileDocumentDto, AnimalProfileDto } from '../../interactors/gen/backendClient'
import { AnimalDocumentItem } from './AnimalDocumentItem'
import { useNavigate } from 'react-router-dom'

interface Props {
  animal: AnimalProfileDto
}

export const AnimalDocumentsTab: React.FC<Props> = ({ animal }) => {
  const [documentsList, setDocumentsList] = React.useState<AnimalProfileDocumentDto[]>(animal.documents)
  const navigate = useNavigate()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Button variant="contained" color="secondary" onClick={() => navigate('./ajouter-un-document')}>
        Ajouter un document
      </Button>
      <Typography
        variant="h2"
        component="h2"
        sx={{ fontSize: '24px', lineHeight: '28.8px', fontWeight: 700, color: '#333333' }}
      >
        Documents importants
      </Typography>
      {documentsList?.map((document) => (
        <AnimalDocumentItem
          key={document.id}
          document={document}
          animalId={animal.id}
          setDocumentsList={setDocumentsList}
        />
      ))}
      <Typography
        variant="h2"
        component="h2"
        sx={{ fontSize: '24px', lineHeight: '28.8px', fontWeight: 700, color: '#333333' }}
      >
        Documents d&#39;adoption
      </Typography>
    </Box>
  )
}
