import { ArrowForwardIos, CheckCircleOutlineOutlined, CloseOutlined, Edit } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import { colors } from '../theme'
import { AdoptionOptionalStepType } from './AdoptionOptionalSteps'
import { metricalpEvent } from '@metricalp/react'
import { useAccountsStore } from '../../store/AccountsStore'

interface Props {
  step: AdoptionOptionalStepType
  animalName: string
  onSubmit: ({ step, value, validate }: { step: AdoptionOptionalStepType; value: boolean; validate?: boolean }) => void
  stepValue: boolean | null
  newAnimalName?: string
  operationDate?: string
}

export const AdoptionOptionalStep: React.FC<Props> = ({
  step,
  animalName,
  onSubmit,
  stepValue,
  newAnimalName,
  operationDate,
}) => {
  const [isEdit, setIsEdit] = React.useState(false)

  function getTextForOptionalStep(): string {
    switch (step) {
      case 'new-name':
        if (stepValue === true && newAnimalName) {
          return `${animalName} deviendra ${newAnimalName} dans quelques jours !`
        }
        if (stepValue === false) {
          return `Pensez à trouver un nouveau nom`
        }
        return 'Avez-vous déjà son nom en tête ?'
      case 'family':
        if (stepValue === true) {
          return 'Bienvenue dans la famille !'
        }
        if (stepValue === false) {
          return `${animalName} est le premier animal dans votre tribu`
        }
        return "Avez-vous d'autres animaux"
      case 'health-care':
        if (stepValue === true) {
          return `${animalName} est assuré contre vents et marées !`
        }
        if (stepValue === false) {
          return `Mieux vaut prévenir que guérir ! Estimez gratuitement le tarif d’une assurance`
        }
        return `${animalName} est-il couvert contre les imprévus ?`
      case 'medical-appointment':
        if (stepValue === true && operationDate) {
          return `La première consultation de ${animalName} est prévue le ${operationDate}`
        }
        if (stepValue === false) {
          return `Planifiez une 1ère consultation pour ${animalName}`
        }
        return 'Avez-vous planifié son 1er rendez-vous de santé ?'
      case 'products':
        if (stepValue === true && !isEdit) {
          return `Il paraît que ${animalName} mérite d'être gâté !`
        }
        if (stepValue === false) {
          return `Comment ça ${animalName} ne mérite pas d'être gâté ?!`
        }
        return `${animalName} sera-t-il gâté ?`
      default:
        return '❓'
    }
  }

  const accountsStore = useAccountsStore()
  const account = accountsStore.connectedAccount

  const onClickChoice = (value: boolean) => {
    setIsEdit(false)
    metricalpEvent({
      type: `click_${step}`,
      adopterId: account?.id,
      adopterName: `${account?.firstName} ${account?.lastName}`,
      value: value ? 'Oui' : 'Non',
    })
    onSubmit({ step, value })
  }

  const onFalseValueClick = () => {
    if (step === 'health-care') {
      metricalpEvent({
        type: `pa_goto_insurance`,
        adopterId: account?.id,
        adopterName: `${account?.firstName} ${account?.lastName}`,
      })
      window.open('https://bit.ly/4a6FFAJ', '_blank', 'noopener,noreferrer,noopener,noreferrer')
    } else {
      onSubmit({ step, value: true })
    }
  }

  const onLineClick = () => {
    if (stepValue === false) {
      onFalseValueClick()
    }
    if (stepValue === true && !isEdit && step !== 'products') {
      if (step === 'health-care') {
        setIsEdit(true)
      } else {
        onSubmit({ step, value: true })
      }
    }
    return
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: step === 'products' ? colors.mintTulip : '',
        px: 2,
        py: 1.5,
        mx: -2,
        cursor: (stepValue === true && !isEdit && step !== 'products') || stepValue === false ? 'pointer' : 'default',
        gap: 2,
      }}
      onClick={onLineClick}
    >
      <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 1 }}>
        <Typography variant="h6" sx={{ fontSize: 25 }}>
          {getEmojiForOptionalStep(step)}
        </Typography>
        <Typography variant="h6" sx={{ fontSize: 17 }}>
          {isEdit ? getTextForOptionalStep() : getTextForOptionalStep()}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        {(stepValue === null || isEdit) && (
          <>
            <IconButton onClick={() => onClickChoice(false)}>
              <CloseOutlined sx={{ fontSize: 30, color: colors.red }} />
            </IconButton>
            <IconButton onClick={() => onClickChoice(true)}>
              <CheckCircleOutlineOutlined sx={{ fontSize: 30, color: colors.blueSky }} />
            </IconButton>
          </>
        )}
        {((stepValue === true && !isEdit && step !== 'products') || stepValue === false) && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '100%',
              backgroundColor: colors.silver,
              p: '4px',
            }}
          >
            {stepValue === true && !isEdit && step !== 'products' && <Edit sx={{ fontSize: 20, color: 'white' }} />}
            {stepValue === false && <ArrowForwardIos sx={{ fontSize: 20, color: 'white' }} />}
          </Box>
        )}
      </Box>
    </Box>
  )
}

function getEmojiForOptionalStep(step: AdoptionOptionalStepType): string {
  switch (step) {
    case 'new-name':
      return '🎉'
    case 'family':
      return '🐶'
    case 'health-care':
      return '🐾'
    case 'medical-appointment':
      return '👨‍⚕️'
    case 'products':
      return '🎁'
    default:
      return '❓'
  }
}
