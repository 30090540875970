import { createTheme } from '@mui/material'

export const spacingItem = { marginTop: 2 }
export const spacingItemEquivalentForMask = { marginTop: 1 }

export const blue = 'rgba(32, 56, 100, 1)'
export const red = '#BC8076'
export const blueSky = '#698DAC'
export const lightGrey = '#F0F0F0'
export const yellowSemiLight = '#FFC300'
export const lightBlue = '#F6F8FF'
export const lightYellow = '#FFF9E3'
export const lightPink = '#FFE3F2'
export const lightGreen = 'rgba(246, 255, 249, 1)'
export const lightRed = 'rgba(255, 235, 238, 1)'

export const colors = {
  cello: '#203864',
  black: '#000000',
  white: '#FFFFFF',
  azalea: '#F7C8DF',
  mintTulip: '#D2E9F7',
  anakiwa: '#82CFFF',
  silver: '#CCCCCC',
  amber: '#FFC300',
  seaGreen: '#2F7D32',
  athensGray: '#EEEFF3',
  punch: '#D93025',
  peach: '#FFE7AD',
  bermudaGray: '#698DAC',
  geyser: '#D6DEE1',
  secondary: '#FFF8E2',
  yellowSemiLight,
  lightGrey,
  blueSky,
  red,
  blue,
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#F3B700',
      contrastText: '#fff', //button text white instead of black
      light: '#FFF8E2',
    },
    background: {
      default: '#FFF',
    },
    secondary: {
      main: '#203864',
    },
    success: {
      main: '#2F7D32',
    },
    info: {
      main: '#708CA9',
    },
    warning: { main: '#F9A825' },
  },
  typography: {
    fontFamily: '"Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;',
    h1: {
      fontSize: '32px',
      fontWeight: 700,
      color: '#203864',
    },
    h2: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 700,
      color: '#203864',
    },
    h4: { color: yellowSemiLight },
    h5: {
      fontSize: '1.2rem',
    },
    h6: {
      fontSize: '0.9rem',
    },
    body2: {
      fontSize: '14px',
      lineHeight: '16.8px',
      color: '#696b6d',
    },
  },
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#ffe7ad',
          width: '24px',
          height: '24px',
          '&$completed': {
            color: '#F3B700',
          },
          '&$active': {
            color: '#F3B700',
          },
          '& text': {
            // Hide the numbers inside the StepIcons
            display: 'none',
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: '#ffe7ad',
          borderWidth: '4px',
          borderTopStyle: 'solid',
          '&$completed': {
            borderColor: '#F3B700',
          },
          '&$active': {
            borderColor: '#F3B700',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '&.MuiStepLabel-alternativeLabel': {
            flexDirection: 'column-reverse',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            gap: '10px',
          },
        },
        label: {
          '&.MuiStepLabel-label.Mui-active': {
            color: '#F3B700',
            fontWeight: 600,
          },
          '&.MuiStepLabel-label.Mui-completed': {
            color: '#F3B700',
            fontWeight: 600,
          },
          '&.MuiStepLabel-alternativeLabel': {
            marginTop: '0',
          },
          fontWeight: 600,
          fontSize: '14px',
          color: '#203864',
          display: 'flex', // Use flexbox
          alignItems: 'end', // Vertically center the text
          height: '30px', // Set the height to match the icon
          justifyContent: 'center', // Horizontally center the text
          textAlign: 'center', // Ensure text is centered for multiline labels
          lineHeight: '16.1px',
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(255,255,255,0.1)', // or any color you prefer for the active state
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            '& .MuiListItemIcon-root': {
              color: 'white',
            },
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '0',
        },
        flexContainer: {
          gap: '41px',
        },
        indicator: {
          backgroundColor: 'rgba(51, 51, 51, 1)',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '0',
          fontSize: '16px',
          lineHeight: '19.2px',
          textTransform: 'none',
          paddingBottom: '4px',
          paddingLeft: '0',
          paddingRight: '0',
          minWidth: '0',
          fontWeight: 500,
          color: 'rgba(51, 51, 51, 1)',
          '&.Mui-selected': {
            color: 'rgba(51, 51, 51, 1)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            height: '48px',
            fontSize: '16px',
            '& fieldset': {
              borderColor: '#C8D0D4',
              borderRadius: '4px',
              borderWidth: '1px',
            },
            '&:hover fieldset': {
              borderColor: '#FFC300',
            },
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FFC300',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FFC300',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '16px',
          '& fieldset': {
            borderColor: '#C8D0D4',
            borderRadius: '4px',
            borderWidth: '1px',
          },
          '&:hover fieldset': {
            borderColor: '#FFC300',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#FFC300',
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            borderRadius: '8px',
            padding: '24px',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: '2px 2px 9px 0px rgba(42, 80, 103, 0.1)',
          padding: '16px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0',
          paddingBottom: 0,
          ':last-child': {
            paddingBottom: 0,
          },
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        // Name of the slot
        title: {
          // Some CSS
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '24px',
          color: '#203864',
        },
      },
    },
  },
})
