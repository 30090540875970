import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { AdoptionOptionalStepType } from './AdoptionOptionalSteps'
import { isValidPastDateCoherence } from '../../utils/date/isValidPastDateCoherence'
import { ControlledDateField } from '../common/ControlledDateField'
import dayjs, { Dayjs } from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useAccountsStore } from '../../store/AccountsStore'
import { metricalpEvent } from '@metricalp/react'

interface Props {
  isOpen: boolean
  onClose: () => void
  step: AdoptionOptionalStepType | null
  onSubmit: ({
    step,
    value,
    validate,
    data,
  }: {
    step: AdoptionOptionalStepType
    value: boolean
    validate?: boolean
    data?: string
  }) => void
}

const newNameSchema = z.object({
  name: z.string().min(1, 'Le nom est requis'),
})

const medicalAppointmentSchema = z.object({
  date: z.instanceof(dayjs as unknown as typeof Dayjs),
})

type NewNameFormData = z.infer<typeof newNameSchema>
type MedicalAppointmentFormData = z.infer<typeof medicalAppointmentSchema>

export const OptionalStepPopUp: React.FC<Props> = ({ isOpen, onClose, step, onSubmit }) => {
  const getTextForOptionalStep = (): string => {
    switch (step) {
      case 'new-name':
        return "Super ! Comment va-t-il s'appeler ? 😻"
      case 'medical-appointment':
        return 'À quelle date est-elle prévue ? 📅'
      case 'family':
        return "La famille s'agrandit ? 🎉"
      default:
        return ''
    }
  }
  if (!step) return null

  const {
    control: nameControl,
    handleSubmit: handleNameSubmit,
    formState: { errors: nameErrors },
  } = useForm<NewNameFormData>({
    resolver: zodResolver(newNameSchema),
    defaultValues: { name: '' },
  })

  const accountsStore = useAccountsStore()
  const account = accountsStore.connectedAccount

  const { control: dateControl, handleSubmit: handleDateSubmit } = useForm<MedicalAppointmentFormData>({
    resolver: zodResolver(medicalAppointmentSchema),
    defaultValues: { date: undefined },
  })

  const handleFormSubmitName = (data: NewNameFormData) => {
    onSubmit({ step, value: true, validate: true, data: data.name })
    metricalpEvent({
      type: `validate_popup_${step}`,
      adopterId: account?.id,
      adopterName: `${account?.firstName} ${account?.lastName}`,
    })
    return onClose()
  }

  const handleFormSubmitDate = (data: MedicalAppointmentFormData) => {
    metricalpEvent({
      type: `validate_popup_${step}`,
      adopterId: account?.id,
      adopterName: `${account?.firstName} ${account?.lastName}`,
    })
    onSubmit({ step, value: true, validate: true, data: dayjs(data.date).toISOString() })
    return onClose()
  }

  const navigate = useNavigate()
  const handleSubmitWithoutForm = () => {
    metricalpEvent({
      type: `add-animal_popup_${step}`,
      adopterId: account?.id,
      adopterName: `${account?.firstName} ${account?.lastName}`,
    })
    onSubmit({ step, value: true, validate: true })
    navigate('/mes-animaux/ajouter-un-animal')
    return onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      sx={{ '.MuiDialog-paper': { padding: 2, borderRadius: 2, maxWidth: 400 } }}
    >
      <DialogTitle
        id="form-dialog-title"
        sx={{ display: 'flex', alignItems: 'center', gap: 1, fontSize: 20, fontWeight: 700 }}
      >
        {getTextForOptionalStep()}
      </DialogTitle>
      {(step === 'medical-appointment' || step === 'new-name') && (
        <DialogContent>
          {step === 'new-name' && (
            <form onSubmit={handleNameSubmit(handleFormSubmitName)}>
              <Controller
                name="name"
                control={nameControl}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Nom"
                    fullWidth
                    margin="normal"
                    error={!!nameErrors.name}
                    helperText={nameErrors.name ? nameErrors.name.message : ''}
                  />
                )}
              />
            </form>
          )}
          {step === 'medical-appointment' && (
            <form onSubmit={handleDateSubmit(handleFormSubmitDate)}>
              <ControlledDateField
                control={dateControl}
                fieldName="date"
                error={undefined}
                requiredRule={'La date du rendez-vous est requise'}
                validate={(value) => isValidPastDateCoherence(value) || value === null}
                size="small"
                label="Date du rendez-vous"
              />
            </form>
          )}
        </DialogContent>
      )}
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', paddingX: 2, width: '100%', gap: 2 }}>
        {step === 'new-name' && (
          <Button
            onClick={handleNameSubmit(handleFormSubmitName)}
            variant="contained"
            color="primary"
            sx={{ minWidth: 120 }}
          >
            Valider
          </Button>
        )}
        {step === 'medical-appointment' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
            <Button
              onClick={(e) => {
                e.preventDefault()
                metricalpEvent({
                  type: `find-a-vet_popup_${step}`,
                  adopterId: account?.id,
                  adopterName: `${account?.firstName} ${account?.lastName}`,
                })
                navigate('/services?tab=veterinary&list=false')
                return onClose()
              }}
              variant="contained"
              color="primary"
              sx={{ minWidth: 120 }}
            >
              Trouver un véto
            </Button>
            <Button
              onClick={handleDateSubmit(handleFormSubmitDate)}
              variant="contained"
              color="primary"
              sx={{ minWidth: 120 }}
            >
              Valider
            </Button>
          </Box>
        )}
        {step === 'family' && (
          <Button onClick={() => handleSubmitWithoutForm()} variant="contained" color="primary">
            Ajouter un autre animal
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
