import { Box, Grid, Tab, Tabs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AnimalProfilesClient } from '../../interactors/clients/AnimalProfilesClient'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import { BeRealsClient } from '../../interactors/clients/BeRealsClient'
import { AdoptionAttemptDto, AnimalDto, AnimalProfileDto } from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { AdoptionFurtherInfo } from '../Adoptions/AdoptionFurtherInfo'
import { EverythingForMyAnimal } from '../Shopping/EverythingForMyAnimal'
import { AnimalCard } from './AnimalCard'
import { AnimalDocumentsTab } from './AnimalDocumentsTab'
import { AnimalFurtherInfo } from './AnimalFurtherInfo'
import { AnimalHealthOperationsTab } from './AnimalHealthOperationsTab'
import { AnimalHealthTab } from './Health/AnimalHealthTab'

type TabKey = 'informations' | 'documents' | 'shopping' | 'history' | 'appointments' | 'health'

export const AnimalDetailsScreen: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const [adoptionAttempt, setAdoptionAttempt] = useState<AdoptionAttemptDto | null>(null)
  const [animal, setAnimal] = useState<AnimalProfileDto | null>(null)
  const [historicalAnimal, setHistoricalAnimal] = useState<AnimalDto | null>(null)
  const [beRealAlreadyDone, setBeRealAlreadyDone] = useState(false)
  const { animalId } = useParams() as { animalId: string }
  const accountsStore = useAccountsStore()
  const navigate = useNavigate()

  console.log(adoptionAttempt?.status)

  const [currentTabIndex, setCurrentTabIndex] = useState<TabKey>('shopping')

  const handleChange = (_: React.SyntheticEvent, newTabKey: TabKey) => {
    setCurrentTabIndex(newTabKey)
    navigate(`/mes-animaux/${animalId}?tab=${newTabKey}&category=`)
  }

  useEffect(() => {
    const tab = queryParams.get('tab') as TabKey
    if (tab) {
      setCurrentTabIndex(tab)
    }
  }, [queryParams])

  function a11yProps(tabKey: TabKey) {
    return {
      id: `simple-tab-${tabKey}`,
      value: tabKey,
      'aria-controls': `simple-tabpanel-${tabKey}`,
      key: tabKey,
    }
  }

  useEffect(() => {
    const fetchAdoptionAttemptAndAnimal = async () => {
      const animal = await AnimalProfilesClient.getAnimalById(animalId)
      const adoptionAttempt = accountsStore.connectedAccount?.adoptionAttempts?.find(
        (attempt) => attempt.animalId === animal.fromCustomerAnimal?.animalId
      )
      setAdoptionAttempt(adoptionAttempt || null)

      if (animal.fromCustomerAnimal?.animalId) {
        const historicalAnimal = await AnimalsClient.getAnimalById(animal.fromCustomerAnimal?.animalId)
        setHistoricalAnimal(historicalAnimal)
      }

      const beRealAlreadyDone = await BeRealsClient.checkIfBeRealExists(animalId)
      setBeRealAlreadyDone(beRealAlreadyDone)

      setAnimal(animal)
    }

    fetchAdoptionAttemptAndAnimal()
  }, [])

  if (!animal) return null

  return (
    <Box sx={{ padding: { xs: 0, md: 2 }, minHeight: '100%', pb: 20 }}>
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', mb: 10, p: 4 }}>
        <Grid container spacing={3} sx={{ pb: 4 }}>
          <Grid item xs={12} md={3.5}>
            <Box sx={{ mt: { xs: 0, md: 6 } }}>
              <AnimalCard animal={animal} setAnimal={setAnimal} beRealAlreadyDone={beRealAlreadyDone} />
            </Box>
          </Grid>
          <Grid item xs={12} md={8.5}>
            <Tabs
              value={currentTabIndex}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
            >
              <Tab
                label={`Tout pour ${animal.name}`}
                {...a11yProps('shopping')}
                onClick={() => navigate(`/mes-animaux/${animalId}?tab=shopping`)}
              />
              <Tab label="Informations" {...a11yProps('informations')} />
              {/* <Tab label="Santé" {...a11yProps('health')} /> */}

              {historicalAnimal && <Tab label="Historique avant adoption" {...a11yProps('history')} />}
            </Tabs>

            <Box sx={{ mt: 3 }}>
              <TabPanel value={currentTabIndex} index={'informations' as TabKey}>
                <AnimalFurtherInfo animal={animal} setAnimal={setAnimal} />
              </TabPanel>
              <TabPanel value={currentTabIndex} index={'appointments' as TabKey}>
                <AnimalHealthOperationsTab animal={animal} />
              </TabPanel>
              <TabPanel value={currentTabIndex} index={'documents' as TabKey}>
                <AnimalDocumentsTab animal={animal} />
              </TabPanel>
              <TabPanel value={currentTabIndex} index={'health' as TabKey}>
                <AnimalHealthTab animalProfile={animal} setAnimalProfile={setAnimal} />
              </TabPanel>
              <TabPanel value={currentTabIndex} index={'shopping' as TabKey}>
                <EverythingForMyAnimal animalProfile={animal} setAnimalProfile={setAnimal} />
              </TabPanel>
              {historicalAnimal && (
                <TabPanel value={currentTabIndex} index={'history' as TabKey}>
                  <AdoptionFurtherInfo animal={historicalAnimal} defaultExpanded hasControls={false} />
                </TabPanel>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

interface TabPanelProps<T> {
  children?: React.ReactNode
  index: T
  value: T
}

function TabPanel<T>(props: TabPanelProps<T>) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
