import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import {
  getReadableAnimalActivity,
  getReadableAnimalCorpulence,
  getReadableAnimalHealthTestStatus,
  getReadableAnimalSize,
  getReadableAnimalSterilizedOrNeutered,
  getReadableTattooLocation,
} from '../../domain/Animal/AnimalDisplay'
import { AnimalProfileDto } from '../../interactors/gen/backendClient'
import { AdoptionFurtherInfoChipsItem } from '../Adoptions/AdoptionFurtherInfoChipsItem'
import { AdoptionFurtherInfoItem } from '../Adoptions/AdoptionFurtherInfoItem'
import { ReactComponent as AddressBookIcon } from '../icons/AddressBookIcon.svg'
import { ReactComponent as AmbulanceIcon } from '../icons/AmbulanceIcon.svg'
import { ReactComponent as BirthdayCakeIcon } from '../icons/BirthdayCakeIcon.svg'
import { ReactComponent as HomeIcon } from '../icons/HomeIcon.svg'
import { ReactComponent as HomeLocationIcon } from '../icons/HomeLocationIcon.svg'
import { ReactComponent as PawIcon } from '../icons/PawIcon.svg'
import { AnimalDescriptionItem } from './AnimalDescriptionItem'
import { EditAnimalFurtherInfoModal } from './EditAnimalFurtherInfoModal'

interface Props {
  animal: AnimalProfileDto
  setAnimal: (animal: AnimalProfileDto) => void
}

export const AnimalFurtherInfo: React.FC<Props> = ({ animal, setAnimal }) => {
  const initialAnimalWeight =
    animal.formAndActivity && animal.formAndActivity?.weight
      ? animal.formAndActivity?.weight[animal.formAndActivity?.weight.length - 1]?.weightInKgs
      : undefined
  const genealogyText = [
    { key: 'Enregistrement LOF', value: animal.breed?.isPedigreeRegistered ? 'LOF' : 'Non-LOF' },
    {
      key: 'N° LOOF',
      value: animal.breed?.registrationNumber ? animal.breed?.registrationNumber.toString() : 'N/A',
    },
    {
      key: 'N° animaux dans la portée',
      value: animal.breed?.numberOfAnimalsInLitter ? animal.breed?.numberOfAnimalsInLitter.toString() : 'N/A',
    },
  ]
  const activityText = [
    {
      key: 'Poids',
      value:
        animal.formAndActivity?.weight && animal.formAndActivity.weight.length > 0 ? `${initialAnimalWeight}kg` : 'N/A',
    },
    {
      key: 'Taille',
      value: animal.formAndActivity?.size ? getReadableAnimalSize(animal.formAndActivity.size) : 'N/A',
    },
    {
      key: 'Corpulence',
      value: animal.formAndActivity?.corpulence
        ? getReadableAnimalCorpulence(animal.formAndActivity.corpulence)
        : 'N/A',
    },
    {
      key: 'Activité',
      value: animal.formAndActivity?.activity ? getReadableAnimalActivity(animal.formAndActivity.activity) : 'N/A',
    },
  ]
  const identityText = [
    {
      key: 'Race',
      value: animal.breed?.breed ?? 'N/A',
    },
    { key: 'Puce électronique', value: animal.identificationNumber ?? 'N/A' },
    { key: 'Tatouage', value: animal.tattoo?.number ?? 'N/A' },
    {
      key: 'Emplacement du tatouage',
      value: animal.tattoo?.location ? getReadableTattooLocation(animal.tattoo.location) : 'N/A',
    },
  ]
  const healthText = [
    /* {
      key: 'Statut vaccinal',
      value: animal.vaccination.vaccinationStatus
        ? getReadableAnimalVaccineStatus(animal.vaccination.vaccinationStatus)
        : 'N/A',
    }, */
    {
      key: 'Stérilisé(e)',
      value: animal.health.sterilizedOrNeutered
        ? getReadableAnimalSterilizedOrNeutered(animal.health.sterilizedOrNeutered)
        : 'N/A',
    },
    {
      key: 'Test FIV',
      value: animal.health.FIV ? getReadableAnimalHealthTestStatus(animal.health.FIV) : 'N/A',
    },
    {
      key: 'Test FeLV',
      value: animal.health.FeLV ? getReadableAnimalHealthTestStatus(animal.health.FeLV) : 'N/A',
    },
  ]

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2" component="p">
          Général
        </Typography>
        <EditAnimalFurtherInfoModal animal={animal} setAnimal={setAnimal} />
      </Box>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={12}>
          <AnimalDescriptionItem
            icon={<PawIcon fill="#333333" />}
            title="Description"
            text={animal.description || 'Aucune description pour le moment.'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <AdoptionFurtherInfoItem icon={<HomeIcon fill="#333333" />} title="Identité" text={identityText} />

            <AdoptionFurtherInfoItem icon={<AmbulanceIcon fill="#333333" />} title="Santé" text={healthText} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <AdoptionFurtherInfoItem
              icon={<AddressBookIcon fill="#333333" />}
              title="Forme et activité"
              text={activityText}
            />

            <AdoptionFurtherInfoItem
              icon={<BirthdayCakeIcon fill="#333333" />}
              title="Portée et généalogie"
              text={genealogyText}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <AdoptionFurtherInfoChipsItem
            icon={<HomeLocationIcon />}
            title="Caractéristiques"
            text={animal.characteristics || ['Aucune caractéristique renseignée']}
          />
        </Grid>
      </Grid>
    </>
  )
}
