import { Assignment, Cancel } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Chip, Typography } from '@mui/material'
import React from 'react'
import { useMutation } from 'react-query'
import { getReadableAnimalDocumentType } from '../../domain/Animal/AnimalDisplay'
import { backendClient } from '../../interactors/clients/client'
import { AnimalDocumentDtoType, AnimalProfileDocumentDto } from '../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { getAnimalDocumentsPhotoSrc } from '../../utils/S3-links'
import { colors, lightBlue, theme } from '../theme'
import dayjs from 'dayjs'

type Props = {
  document: AnimalProfileDocumentDto
  animalId: string
  setDocumentsList?: React.Dispatch<React.SetStateAction<AnimalProfileDocumentDto[]>>
}

export const AnimalDocumentItem: React.FC<Props> = ({ document, animalId, setDocumentsList }) => {
  const globalSnackbar = useGlobalSnackbarStore()
  // Mutation to delete the document
  const mutation = useMutation(
    async () => await backendClient.delete(`adopters-platform/animalprofiles/${animalId}/documents/${document.id}`),
    {
      onSuccess: () => {
        globalSnackbar.triggerSuccessMessage(`Le document a bien été supprimé !`)
        setDocumentsList?.((prev) => prev.filter((doc) => doc.id !== document.id))
      },
    }
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        py: '8px',
        px: '16px',
        borderRadius: '5px',
        gap: '10px',
        border: '1px solid',
        borderColor: colors.lightGrey,
        backgroundColor: colors.lightGrey,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '16px',
          color: colors.black,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px',
            color: colors.black,
          }}
        >
          <Assignment sx={{ color: theme.palette.secondary.main }} />
          <Chip
            sx={{ backgroundColor: lightBlue }}
            label={document.type ? getReadableAnimalDocumentType(document.type as AnimalDocumentDtoType) : ''}
          />
        </Box>
        <LoadingButton
          color="inherit"
          variant="text"
          loading={mutation.isLoading}
          onClick={() => mutation.mutate()}
          sx={{
            borderRadius: '10px',
            p: '10px',
            minWidth: '0',
          }}
        >
          <Cancel sx={{ color: 'grey' }} />
        </LoadingButton>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Typography sx={{ fontStyle: 'italic', fontSize: '14px' }}>{`${dayjs(document.uploadedAt).format(
          'DD/MM/YY'
        )}`}</Typography>
        {document.name && <Typography>{document.name}</Typography>}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          gap: '16px',
          color: colors.black,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth
          component="a"
          href={getAnimalDocumentsPhotoSrc(document.documentKey)}
          target="_blank"
        >
          Consulter
        </Button>
      </Box>
    </Box>
  )
}
