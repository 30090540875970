import { Box, Typography } from '@mui/material'
import React from 'react'
import { AnimalProfileDto } from '../../../../interactors/gen/backendClient'
import { SoberCollapsible } from '../../../common/SoberCollapsible'
import { getGoals } from '../GetScoreGoals'
import dayjs from 'dayjs'
import { BrandItem } from '../../../Shopping/BrandItem'
import { Categories } from '../AnimalHealthTab'

interface Props {
  animalProfile: AnimalProfileDto
  setSelectedCategory: (category: Categories) => void
}

export const Score: React.FC<Props> = ({ animalProfile, setSelectedCategory }) => {
  const goals = getGoals(animalProfile, setSelectedCategory)
  const achievedGoals = goals.filter((goal) => goal.achieved)
  const unachievedGoals = goals.filter((goal) => !goal.achieved)
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '22px', mx: { xs: -4, md: 0 }, px: { xs: 1, md: 0 } }}>
      <Typography variant="h2" component="h2" sx={{ color: 'black', textAlign: 'center', mt: 2, fontWeight: 400 }}>
        Score bien-être de {animalProfile.name}
      </Typography>
      <Typography variant="h3" sx={{ color: 'black', textAlign: 'center', fontSize: '26px', fontWeight: 700 }}>
        {animalProfile.health.score?.healthScore ?? '0'}
      </Typography>
      <Typography variant="body1" sx={{ color: 'black', textAlign: 'center', fontSize: '16px' }}>
        {animalProfile.health.score?.feedingQuestions?.sort((a, b) =>
          dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1
        )[0]?.energeticalNeeds
          ? `${
              animalProfile.health.score?.feedingQuestions?.sort((a, b) =>
                dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1
              )[0].energeticalNeeds
            } kcal/jour`
          : ''}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
        <Typography variant="body1" sx={{ mb: '8px', fontSize: '16px' }}>
          {unachievedGoals.length} éléments n&#39;ont pas atteints d&#39;objectifs
        </Typography>
        {unachievedGoals.map((goal, index) => (
          <SoberCollapsible
            key={`${goal.title}-${index}`}
            title={goal.title}
            subtitle={goal.subtitle}
            icon={goal.icon}
            defaultExpanded={!goal.achieved}
          >
            {goal.description}
          </SoberCollapsible>
        ))}
      </Box>
      <BrandItem
        animalProfile={animalProfile}
        title="Assurance Kozoo"
        description={`Remboursement de 50% à 100%\n
        Plafond de 1000€ à 3000€`}
        logoUrl="https://www.kozoo.eu/wp-content/themes/kozoo/assets/images/kozoo-assurance-chien-chat-digitale.svg"
        imgSrc="https://animals-photos.s3.amazonaws.com/2023-04-05+(1).jpg"
        brandName="kozoo"
        catchPhrase="Devis gratuit"
        redirectUrl="https://bit.ly/4a6FFAJ"
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
        <Typography variant="body1" sx={{ mb: '8px', fontSize: '16px' }}>
          {achievedGoals.length} objectifs atteints
        </Typography>
        {achievedGoals.map((goal, index) => (
          <SoberCollapsible
            key={`${goal.title}-${index}`}
            title={goal.title}
            subtitle={goal.subtitle}
            icon={goal.icon}
            defaultExpanded={!goal.achieved}
          >
            {goal.description}
          </SoberCollapsible>
        ))}
        {/* <SoberCollapsible title="Vermifuge" subtitle="Super" icon={<LocalPharmacy sx={{ color: 'lightGreen' }} />}>
          <Typography variant="body2">
            Le score de santé de votre animal est calculé en fonction de son âge, de son poids, de son activité physique
            et de son alimentation.
          </Typography>
        </SoberCollapsible>
        <SoberCollapsible title="Comportement" subtitle="Score de 6/6" icon={<Pets sx={{ color: 'lightGreen' }} />}>
          <Typography variant="body2">
            Le score de santé de votre animal est calculé en fonction de son âge, de son poids, de son activité physique
            et de son alimentation.
          </Typography>
        </SoberCollapsible> */}
      </Box>
    </Box>
  )
}
