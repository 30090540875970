import { Box, Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { AnimalDto, CustomerDto } from '../../interactors/gen/backendClient'
import { ReactComponent as HomeLocationIcon } from '../icons/HomeLocationIcon.svg'
import { theme } from '../theme'
import { AdoptionFurtherInfoItem } from './AdoptionFurtherInfoItem'
import { backendClient } from '../../interactors/clients/client'
import { Appearance, loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { CheckoutForm } from '../common/StripeCheckoutForm'

interface Props {
  animal: AnimalDto
  association: CustomerDto
  attemptId: string
  setOpenPaymentModal: (value: boolean) => void
  disabled?: boolean
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string)

export const AdoptionPaymentCard: React.FC<Props> = ({
  animal,
  association,
  attemptId,
  setOpenPaymentModal,
  disabled = false,
}) => {
  const [step, setStep] = useState(1)
  const [clientSecret, setClientSecret] = useState<string>('')
  const [animalPrice, setAnimalPrice] = useState<number>(Number(animal.adoption.priceInEuros) || 0)

  const createPaymentIntent = async () => {
    if (!animalPrice) {
      return console.log('Price is not set')
    }
    const price = (animalPrice + 5) * 100
    const response = await backendClient.post(
      `/adopters-platform/stripe/create-payment-intent?attemptId=${attemptId}`,
      {
        items: [{ reference: 'ADOPTION', quantity: 1, price }],
      }
    )
    setClientSecret(response.data)
  }

  const onSubmit = async () => {
    if ((!animal.adoption.priceInEuros || animal.adoption.priceInEuros === 0) && !animalPrice) {
      return setStep(2)
    }
    await createPaymentIntent()
  }

  const appearance: Appearance = {
    theme: 'stripe',
  }

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  }

  const checkText = [
    { key: 'Adresse', value: association.address ?? 'N/A' },
    {
      key: 'Code postal',
      value: association.postalCode ?? 'N/A',
    },
    {
      key: 'Ville',
      value: association.city ?? 'N/A',
    },
    {
      key: 'Ordre du chèque',
      value: association.paymentInfo?.checkOrder ?? 'N/A',
    },
  ]
  const wireTransferText = [
    {
      key: 'Prénom',
      value: association.paymentInfo?.IBANFirstName ?? 'N/A',
    },
    {
      key: 'Nom',
      value: association.paymentInfo?.IBANLastName ?? 'N/A',
    },
    {
      key: 'IBAN',
      value: association.paymentInfo?.IBANNumber ? formatIBANNumber(association.paymentInfo?.IBANNumber) : 'N/A',
    },
  ]
  const onlinePaymentText = [
    {
      key: 'Via ce lien',
      value: association.paymentInfo?.onlinePaymentURL ?? 'N/A',
    },
  ]

  return clientSecret ? (
    <Elements options={options} stripe={stripePromise}>
      <CheckoutForm
        price={animalPrice + 5}
        redirectionLink={`https://adoptant.petso.fr/adoptions/${attemptId}`}
        adoptionPayment
      />
    </Elements>
  ) : step < 2 ? (
    <Card>
      <CardContent sx={{ px: '16px' }}>
        <Typography variant="h2" component="p">
          Procéder au paiement
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', mt: 2 }}>
          <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: '16.8px', color: '#696B6D' }}>
            Montant à payer :{' '}
            {animal.adoption.priceInEuros ? `${animal.adoption.priceInEuros}€` : 'à confirmer avec l’association'}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: '16.8px', color: '#696B6D' }}>
            Mode(s) de paiement disponible(s): {getPaymentMethod(association.paymentInfo)}
          </Typography>
          <Grid container spacing={2}>
            {association.paymentInfo?.checkOrder && (
              <Grid item xs={12} md={6}>
                <AdoptionFurtherInfoItem
                  icon={<HomeLocationIcon fill="#333333" />}
                  title="Par chèque"
                  text={checkText}
                />
              </Grid>
            )}
            {association.paymentInfo?.IBANNumber && (
              <Grid item xs={12} md={6}>
                <AdoptionFurtherInfoItem
                  icon={<HomeLocationIcon fill="#333333" />}
                  title="Par virement bancaire"
                  text={wireTransferText}
                />
              </Grid>
            )}
            {association.paymentInfo?.onlinePaymentURL && (
              <Grid item xs={12} md={12}>
                <AdoptionFurtherInfoItem
                  icon={<HomeLocationIcon fill="#333333" />}
                  title="Paiement en ligne"
                  text={onlinePaymentText}
                />
              </Grid>
            )}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                px: '20px',
                py: '13px',
                borderRadius: '200px',
                color: theme.palette.secondary.main,
                fontSize: '16px',
                lineHeight: '19.2px',
                fontWeight: 600,
                boxShadow: 'none',
                '&:disabled': {
                  py: '13px',
                  border: 'none',
                  backgroundColor: 'rgba(243, 243, 245, 1)',
                  color: '#696B6D',
                },
              }}
              onClick={() => setOpenPaymentModal(true)}
              disabled={disabled}
            >
              {disabled ? 'En attente de validation' : `J'ai effectué le paiement`}
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                px: '20px',
                py: '13px',
                borderRadius: '200px',
                color: 'white',
                backgroundColor: theme.palette.secondary.main,
                fontSize: '16px',
                lineHeight: '19.2px',
                fontWeight: 600,
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: theme.palette.secondary.dark,
                },
                '&:disabled': {
                  py: '13px',
                  border: 'none',
                  backgroundColor: 'rgba(243, 243, 245, 1)',
                  color: '#696B6D',
                },
              }}
              onClick={() => onSubmit()}
              disabled={disabled}
            >
              {`Payer en ligne`}
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  ) : (
    <Card>
      <CardContent sx={{ px: '16px' }}>
        <Typography variant="h2" component="p">
          Procéder au paiement
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', mt: 2 }}>
          <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: '16.8px', color: '#696B6D' }}>
            {`L'association n'ayant pas entré de montant, veuillez confirmer le montant à payer avec elle.`}
          </Typography>
          <Typography variant="h2" component="p" sx={{ color: '#4f4f4f' }}>
            {association.name}
          </Typography>
          <Typography variant="body2" sx={{ color: '#4f4f4f' }}>
            <b>Téléphone</b> {association.phoneNumber}
          </Typography>
          <Typography variant="body2" sx={{ color: '#4f4f4f' }}>
            <b>Mail</b> {association.email}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '14px', lineHeight: '16.8px', color: '#696B6D' }}>
            Ensuite entrez le ci-dessous pour procéder au paiement par carte bancaire.
          </Typography>
          <TextField
            label="Montant à payer"
            variant="outlined"
            onChange={(e) => setAnimalPrice(Number(e.target.value))}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                px: '20px',
                py: '13px',
                borderRadius: '200px',
                color: 'white',
                backgroundColor: theme.palette.secondary.main,
                fontSize: '16px',
                lineHeight: '19.2px',
                fontWeight: 600,
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: theme.palette.secondary.dark,
                },
                '&:disabled': {
                  py: '13px',
                  border: 'none',
                  backgroundColor: 'rgba(243, 243, 245, 1)',
                  color: '#696B6D',
                },
              }}
              onClick={() => onSubmit()}
              disabled={disabled}
            >
              {`Payer en ligne`}
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

function formatIBANNumber(IBANNumber: string): string {
  return IBANNumber.replace(/(.{4})/g, '$1 ').trim()
}

function getPaymentMethod(paymentInfo: CustomerDto['paymentInfo']): string {
  const paymentMethod = []
  if (paymentInfo?.checkOrder) {
    paymentMethod.push('chèque')
  }
  if (paymentInfo?.IBANNumber) {
    paymentMethod.push('virement')
  }
  if (paymentInfo?.onlinePaymentURL) {
    paymentMethod.push('en ligne')
  }
  return paymentMethod.join(', ')
}
