import { Box, Chip } from '@mui/material'
import React, { useEffect } from 'react'
import { theme } from '../../theme'
import { AnimalProfileDto } from '../../../interactors/gen/backendClient'
import { PestControl } from './Categories/PestControl'
import { Score } from './Categories/Score'
import { Environment } from './Categories/Environment'
import { Behavior } from './Categories/Behavior'
import { Feeding } from './Categories/Feeding'
import { useNavigate } from 'react-router-dom'

export enum Categories {
  Score = 'score',
  /* Vaccination = 'vaccination', */
  Deworming = 'deworming',
  FleaControl = 'fleaControl',
  Feeding = 'feeding',
  Behavior = 'behavior',
  Environment = 'environment',
}

interface Props {
  animalProfile: AnimalProfileDto
  setAnimalProfile: (animalProfile: AnimalProfileDto) => void
}

export const AnimalHealthTab: React.FC<Props> = ({ animalProfile, setAnimalProfile }) => {
  const [selectedCategory, setSelectedCategory] = React.useState<Categories>(Categories.Score)

  const navigate = useNavigate()
  const queryParams = new URLSearchParams(window.location.search)
  const handleCategoryChange = (category: Categories) => {
    setSelectedCategory(category)
    navigate(`/mes-animaux/${animalProfile.id}?tab=shopping&category=sante&subCategory=${category}`)
  }

  useEffect(() => {
    const tab = queryParams.get('subCategory') as Categories
    if (tab) {
      setSelectedCategory(tab)
    } else {
      setSelectedCategory(Categories.Score)
    }
  }, [queryParams])

  const renderCategory = () => {
    switch (selectedCategory) {
      case Categories.Deworming:
        return (
          <PestControl
            data={animalProfile.health.deworming}
            type={selectedCategory}
            animalProfile={animalProfile}
            setAnimalProfile={setAnimalProfile}
          />
        )
      /* case Categories.Vaccination:
        return <Box>Vaccination</Box> */
      case Categories.FleaControl:
        return (
          <PestControl
            data={animalProfile.health.fleaControl}
            type={selectedCategory}
            animalProfile={animalProfile}
            setAnimalProfile={setAnimalProfile}
          />
        )
      case Categories.Environment:
        return (
          <Environment
            animalProfile={animalProfile}
            setAnimalProfile={setAnimalProfile}
            setSelectedCategory={setSelectedCategory}
          />
        )
      case Categories.Behavior:
        return (
          <Behavior
            animalProfile={animalProfile}
            setAnimalProfile={setAnimalProfile}
            setSelectedCategory={setSelectedCategory}
          />
        )
      case Categories.Feeding:
        return (
          <Feeding
            animalProfile={animalProfile}
            setAnimalProfile={setAnimalProfile}
            setSelectedCategory={setSelectedCategory}
          />
        )
      case Categories.Score:
        return <Score setSelectedCategory={setSelectedCategory} animalProfile={animalProfile} />
      default:
        return <Box />
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 3 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          overflowX: 'auto',
          mx: { xs: -4, md: 0 },
          px: { xs: 1, md: 0 },
        }}
      >
        {Object.values(Categories).map((category) => (
          <Chip
            key={category}
            label={translateCategories(category)}
            clickable
            onClick={() => handleCategoryChange(category)}
            sx={{
              backgroundColor: selectedCategory === category ? theme.palette.secondary.main : 'transparent',
              border: `1px solid ${theme.palette.secondary.main}`,
              color: selectedCategory === category ? 'white' : theme.palette.secondary.main,
              fontSize: '14px',
              px: '12px',
              py: '20px',
              borderRadius: '20px',
              fontWeight: 'bold',
              '&:focus': {
                backgroundColor: theme.palette.secondary.main,
                color: 'white',
              },
            }}
          />
        ))}
      </Box>
      {renderCategory()}
    </Box>
  )
}

function translateCategories(category: Categories): string {
  switch (category) {
    case Categories.Deworming:
      return 'Vermifuge'
    /* case Categories.Vaccination:
      return 'Vaccination' */
    case Categories.FleaControl:
      return 'Insectifuge'
    case Categories.Environment:
      return 'Environnement'
    case Categories.Behavior:
      return 'Comportement'
    case Categories.Feeding:
      return 'Nutrition'
    case Categories.Score:
      return 'Bilan'
    default:
      return ''
  }
}
