import { Box, Theme, useMediaQuery } from '@mui/material'
import React from 'react'
import {
  getReadableAnimalActivity,
  getReadableAnimalCorpulence,
  getReadableAnimalHealthTestStatus,
  getReadableAnimalSize,
  getReadableAnimalSterilizedOrNeutered,
  getReadableTattooLocation,
} from '../../domain/Animal/AnimalDisplay'
import { AnimalDto } from '../../interactors/gen/backendClient'
import { AdoptionFurtherInfoItem } from '../Adoptions/AdoptionFurtherInfoItem'
import { SoberCollapsible } from '../common/SoberCollapsible'
import { ReactComponent as AddressBookIcon } from '../icons/AddressBookIcon.svg'
import { ReactComponent as AmbulanceIcon } from '../icons/AmbulanceIcon.svg'
import { ReactComponent as BirthdayCakeIcon } from '../icons/BirthdayCakeIcon.svg'
import { ReactComponent as HomeIcon } from '../icons/HomeIcon.svg'

interface Props {
  animal: AnimalDto
}

export const FurtherInfo: React.FC<Props> = ({ animal }) => {
  const initialAnimalWeight =
    animal.formAndActivity && animal.formAndActivity?.weight
      ? animal.formAndActivity?.weight[animal.formAndActivity?.weight.length - 1]?.weightInKgs
      : undefined

  const genealogyText = [
    { key: 'Enregistrement LOF', value: animal.breedData?.isPedigreeRegistered ? 'LOF' : 'Non-LOF' },
    {
      key: 'N° LOOF',
      value: animal.breedData?.registrationNumber ? animal.breedData?.registrationNumber.toString() : 'N/A',
    },
    {
      key: 'N° animaux dans la portée',
      value: animal.breedData?.numberOfAnimalsInLitter ? animal.breedData?.numberOfAnimalsInLitter.toString() : 'N/A',
    },
  ]
  const activityText = [
    {
      key: 'Poids',
      value:
        animal.formAndActivity?.weight && animal.formAndActivity.weight.length > 0 ? `${initialAnimalWeight}kg` : 'N/A',
    },
    {
      key: 'Taille',
      value: animal.formAndActivity?.size ? getReadableAnimalSize(animal.formAndActivity.size) : 'N/A',
    },
    {
      key: 'Corpulence',
      value: animal.formAndActivity?.corpulence
        ? getReadableAnimalCorpulence(animal.formAndActivity.corpulence)
        : 'N/A',
    },
    {
      key: 'Activité',
      value: animal.formAndActivity?.activity ? getReadableAnimalActivity(animal.formAndActivity.activity) : 'N/A',
    },
  ]
  const identityText = [
    {
      key: 'Race',
      value: animal.breedData?.breed ?? 'N/A',
    },
    { key: 'Puce électronique', value: animal.identificationNumber ?? 'N/A' },
    { key: 'Tatouage', value: animal.tattoo?.number ?? 'N/A' },
    {
      key: 'Emplacement du tatouage',
      value: animal.tattoo?.location ? getReadableTattooLocation(animal.tattoo.location) : 'N/A',
    },
  ]
  const healthText = [
    /* {
      key: 'Statut vaccinal',
      value: animal.vaccination.vaccinationStatus
        ? getReadableAnimalVaccineStatus(animal.vaccination.vaccinationStatus)
        : 'N/A',
    }, */
    {
      key: 'Stérilisé(e)',
      value: animal.health.sterilizedOrNeutered
        ? getReadableAnimalSterilizedOrNeutered(animal.health.sterilizedOrNeutered)
        : 'N/A',
    },
    {
      key: 'Test FIV',
      value: animal.health.FIV ? getReadableAnimalHealthTestStatus(animal.health.FIV) : 'N/A',
    },
    {
      key: 'Test FeLV',
      value: animal.health.FeLV ? getReadableAnimalHealthTestStatus(animal.health.FeLV) : 'N/A',
    },
  ]

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <SoberCollapsible title="Informations" noLeftPadding sx={{ mt: '24px' }} defaultExpanded={!isMobile}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <AdoptionFurtherInfoItem icon={<HomeIcon fill="#333333" />} title="Identité" text={identityText} />
        <AdoptionFurtherInfoItem icon={<AmbulanceIcon fill="#333333" />} title="Santé" text={healthText} />
        <AdoptionFurtherInfoItem
          icon={<AddressBookIcon fill="#333333" />}
          title="Forme et activité"
          text={activityText}
        />

        <AdoptionFurtherInfoItem
          icon={<BirthdayCakeIcon fill="#333333" />}
          title="Portée et généalogie"
          text={genealogyText}
        />
      </Box>
    </SoberCollapsible>
  )
}
