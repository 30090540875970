import { Box, Button, Card, CardContent, CardHeader, CircularProgress, IconButton, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { AdoptionAttemptDto } from '../../interactors/gen/backendClient'
import { getAdoptionAttemptsDocumentsSrc } from '../../utils/S3-links'
import { ReactComponent as CrossIcon } from '../icons/CrossIcon.svg'
import { lightBlue, theme } from '../theme'

interface Props {
  setFile: (file: File | null) => void
  buttonsShouldBeDisabled: boolean
  originalFileKey: string | undefined
  onSubmit: (data: Partial<AdoptionAttemptDto>, type: 'certificate' | 'contract' | 'payment') => Promise<void>
  filetype: 'certificate' | 'contract'
  displayOnly?: boolean
  signedFileKey?: string
}

export const ImportDocumentCard: React.FC<Props> = ({
  setFile,
  buttonsShouldBeDisabled,
  originalFileKey,
  onSubmit,
  displayOnly = false,
  signedFileKey,
  filetype,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFile(acceptedFiles[0])
    },
    [setFile]
  )

  // Disable dropzone if displayOnly
  const { getRootProps, getInputProps, isDragActive, acceptedFiles, open, inputRef } = useDropzone({
    onDrop,
    noClick: true, // Prevents 'onClick' from being included in 'getRootProps()'
    noKeyboard: true, // Optional: disables keyboard interaction
    disabled: displayOnly,
  })
  const removeFile = () => {
    acceptedFiles.length = 0
    acceptedFiles.splice(0, acceptedFiles.length)
    if (inputRef.current) inputRef.current.value = ''
    setFile(null)
    console.log(acceptedFiles)
  }

  const preSubmit = async () => {
    setIsLoading(true)
    await onSubmit({ step: 2 }, filetype)
    setIsLoading(false)
    removeFile()
  }

  const rootProps = getRootProps()

  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <CircularProgress />
      </div>
    )

  const translatedFileType = filetype === 'certificate' ? 'certificat' : 'contrat'
  return (
    <Card sx={{ position: 'relative', backgroundColor: lightBlue }} {...rootProps}>
      <CardHeader title={`Signature du ${translatedFileType}`} sx={{ span: { color: 'black !important' } }} />
      <CardContent sx={{ px: '16px', filter: isDragActive ? 'blur(4px)' : '', transition: 'all 100ms ease-in-out' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: { md: 'row', xs: 'column' },
            gap: { md: '16px', xs: '0px' },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <a
              href={originalFileKey && !buttonsShouldBeDisabled ? getAdoptionAttemptsDocumentsSrc(originalFileKey) : ''}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => {
                if (buttonsShouldBeDisabled) e.preventDefault()
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  px: '20px',
                  py: '11px',
                  borderRadius: '200px',
                  color: 'black',
                  fontSize: '16px',
                  lineHeight: '19.2px',
                  fontWeight: 600,
                  boxShadow: 'none',
                  border: '2px solid',
                  boxSizing: 'border-box',
                  WebkitBoxSizing: 'border-box',
                  MozBoxSizing: 'border-box',
                  width: '100%',
                  borderColor: theme.palette.primary.main,
                  '&:hover': {
                    border: '2px solid',
                    borderColor: theme.palette.primary.main,
                  },
                  '&:disabled': {
                    py: '13px',
                    border: 'none',
                    backgroundColor: 'rgba(243, 243, 245, 1)',
                    color: '#696B6D',
                  },
                }}
                disabled={displayOnly ? false : buttonsShouldBeDisabled}
              >
                {`Consulter le ${translatedFileType}`}
              </Button>
            </a>
            {!displayOnly && (
              <Typography
                variant="h4"
                component="p"
                fontSize="14px"
                lineHeight="16.8px"
                fontWeight={700}
                textAlign="center"
                color="#696B6D"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flexGrow: 1,
                  height: '17px',
                }}
              >
                {buttonsShouldBeDisabled ? 'Document remis en mains propres' : ''}
              </Typography>
            )}
          </Box>
          {!displayOnly ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <input {...getInputProps()} />
              <Button
                variant="contained"
                color="primary"
                type="button"
                sx={{
                  px: '20px',
                  py: '13px',
                  borderRadius: '200px',
                  color: theme.palette.secondary.main,
                  fontSize: '16px',
                  lineHeight: '19.2px',
                  fontWeight: 600,
                  boxShadow: 'none',
                  '&:disabled': {
                    py: '13px',
                    border: 'none',
                    backgroundColor: 'rgba(243, 243, 245, 1)',
                    color: '#696B6D',
                  },
                }}
                onClick={() => {
                  if (!acceptedFiles[0]) {
                    open()
                  } else {
                    preSubmit()
                  }
                }}
              >
                {!acceptedFiles[0]
                  ? `Importer mon ${translatedFileType} signé`
                  : `Envoyer mon ${translatedFileType} signé`}
              </Button>

              <Box sx={{ display: 'flex', gap: '2px', justifyContent: 'center', alignItems: 'center', height: '17px' }}>
                <Typography
                  variant="h4"
                  component="p"
                  fontSize="14px"
                  lineHeight="16.8px"
                  fontWeight={700}
                  textAlign="center"
                  color="#696B6D"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    height: '17px',
                  }}
                >
                  {acceptedFiles[0]?.name || 'Aucun fichier sélectionné'}
                </Typography>
                {acceptedFiles.length > 0 && !buttonsShouldBeDisabled && (
                  <IconButton onClick={removeFile}>
                    <CrossIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          ) : (
            <a href={signedFileKey && getAdoptionAttemptsDocumentsSrc(signedFileKey)} target="_blank" rel="noreferrer">
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  px: '20px',
                  py: '11px',
                  borderRadius: '200px',
                  color: theme.palette.secondary.main,
                  fontSize: '16px',
                  lineHeight: '19.2px',
                  fontWeight: 600,
                  boxShadow: 'none',
                  border: '2px solid',
                  boxSizing: 'border-box',
                  WebkitBoxSizing: 'border-box',
                  MozBoxSizing: 'border-box',
                  borderColor: theme.palette.primary.main,
                  '&:hover': {
                    border: '2px solid',
                    borderColor: theme.palette.primary.main,
                  },
                  '&:disabled': {
                    py: '13px',
                    border: 'none',
                    backgroundColor: 'rgba(243, 243, 245, 1)',
                    color: '#696B6D',
                  },
                }}
                disabled={displayOnly ? false : buttonsShouldBeDisabled}
              >
                {`Télécharger le ${translatedFileType} signé`}
              </Button>
            </a>
          )}
        </Box>
      </CardContent>
      <div
        style={{
          display: isDragActive ? 'flex' : 'none',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '8px',
          border: '2px dashed #FFC107',
          fontWeight: 700,
          transition: 'all 100ms ease-in-out',
        }}
      >
        Déposez votre fichier ici
      </div>
    </Card>
  )
}
