import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimalProfileDto } from '../../interactors/gen/backendClient'
import { AnimalDocumentsTab } from '../Animals/AnimalDocumentsTab'
import { AnimalHealthOperationsTab } from '../Animals/AnimalHealthOperationsTab'
import { AnimalHealthTab } from '../Animals/Health/AnimalHealthTab'
import { AccessoriesCategory } from './Categories/AccessoriesCategory'
import { BlogCategory } from './Categories/BlogCategory'
import { CategoryItem } from './CategoryItem'
import { ShoppingCategory } from './Categories/ShoppingCategory'

interface Props {
  animalProfile: AnimalProfileDto
  setAnimalProfile: (animalProfile: AnimalProfileDto) => void
}

export const EverythingForMyAnimal: React.FC<Props> = ({ animalProfile, setAnimalProfile }) => {
  const categories = [
    {
      title: 'Santé',
      backgroundColor: '#003153', // Light steel blue
      catImgSrc:
        'https://fac.img.pmdstatic.net/fit/http.3A.2F.2Fprd2-bone-image.2Es3-website-eu-west-1.2Eamazonaws.2Ecom.2FFAC.2Fvar.2Ffemmeactuelle.2Fstorage.2Fimages.2Fanimaux.2Fveterinaire-les-conseils.2Fsante-du-chat-comment-bien-choisir-son-veterinaire-46748.2F14842980-1-fre-FR.2Fsante-du-chat-comment-bien-choisir-son-veterinaire.2Ejpg/1200x900/quality/80/crop-from/center/sante-du-chat-comment-bien-choisir-son-veterinaire.jpeg',
      dogImgSrc: 'https://animals-photos.s3.amazonaws.com/dogSante.jpg',
      halfSize: true,
      slug: 'sante',
    },
    {
      title: 'Documents',
      backgroundColor: '#e2e8a6',
      catImgSrc: 'https://i.ytimg.com/vi/NCMXctvVvjI/maxresdefault.jpg',
      dogImgSrc: 'https://i.ytimg.com/vi/NCMXctvVvjI/maxresdefault.jpg',
      halfSize: true,
      slug: 'documents',
    },
    {
      title: 'Rendez-vous & suivi santé',
      backgroundColor: '#a5becc', // Pale olive green
      catImgSrc: 'https://animals-photos.s3.amazonaws.com/trouver+un+ve%CC%81te%CC%81rinaire-chat-1-min.jpg',
      dogImgSrc: 'https://animals-photos.s3.amazonaws.com/pet+wellbeing+1-min.jpg',
      halfSize: false,
      slug: 'rendez-vous-suivi-sante',
    },
    {
      title: `Recommandations pour ${animalProfile.name}`,
      backgroundColor: '#d9a877', // Warm earth tone
      catImgSrc: 'https://animals-photos.s3.amazonaws.com/vue-adorable-chat-mangeant-sa-nourriture+(2).jpg',
      dogImgSrc:
        'https://animals-photos.s3.amazonaws.com/hungry-white-brown-dog-with-big-ears-brown-eyes-ready-eat-bowl-full-food-min.jpg',
      halfSize: false,
      slug: 'recommandations',
    },
    /*     {
      title: 'Equipement et accessoires',
      backgroundColor: '#a5becc', // Light steel blue
      catImgSrc: 'https://animals-photos.s3.amazonaws.com/Pet-equipment-chat-2-min.jpg',
      dogImgSrc: 'https://animals-photos.s3.amazonaws.com/Pet-equipment-Chien-2-min.jpg',
      halfSize: false,
    }, */
    {
      title: 'Trouver un pro',
      backgroundColor: '#b2dfdb', // Light teal
      catImgSrc: 'https://animals-photos.s3.amazonaws.com/Wellbeing-chat-min.webp',
      dogImgSrc: 'https://animals-photos.s3.amazonaws.com/trouver+un+ve%CC%81te%CC%81rinaire-chat-2-min.jpg',
      halfSize: true,
      slug: 'trouver-un-pro',
    },
    {
      title: 'Membres Petso',
      backgroundColor: '#f8c291', // Soft peach
      catImgSrc: 'https://animals-photos.s3.amazonaws.com/autour+de+moi+-+mixte-3-min.jpg',
      dogImgSrc: 'https://animals-photos.s3.amazonaws.com/autour+de+moi+-+mixte-3-min.jpg',
      halfSize: true,
      slug: 'membres-petso',
    },
    /*     {
      title: 'Bien-être',
      backgroundColor: '#e2e8a6', // Pale olive green
      catImgSrc: 'https://animals-photos.s3.amazonaws.com/trouver+un+ve%CC%81te%CC%81rinaire-chat-1-min.jpg',
      dogImgSrc: 'https://animals-photos.s3.amazonaws.com/pet+wellbeing+1-min.jpg',
      halfSize: false,
    }, */
    {
      title: 'Articles et conseils',
      backgroundColor: '#6c757d', // Desaturated dark blue
      catImgSrc: 'https://animals-photos.s3.amazonaws.com/Article+et+conseils+-+Chats-min.jpg',
      dogImgSrc: 'https://animals-photos.s3.amazonaws.com/Article+et+conseils+-+Chien-min.jpg',
      halfSize: false,
      slug: 'articles-et-conseils',
    },
  ]

  const queryParams = new URLSearchParams(window.location.search)
  const category = queryParams.get('category')
  const navigate = useNavigate()
  const [selectedCategory, setSelectedCategory] = React.useState<string | null>(category)

  const scrollTop = () => {
    const chatContainer = document.querySelector('.scrollable-content') as HTMLElement
    chatContainer.scrollTo({ top: 500, behavior: 'smooth' })
  }

  // This function is called when a category is selected. It will set the new selected category in the state and update the URL query parameter.
  const selectACategory = (category: string) => {
    setSelectedCategory(category)
    if (category === 'trouver-un-pro') {
      return navigate(`/services`)
    } else if (category === 'membres-petso') {
      return navigate(`/pet-parents`)
    }
    navigate(`/mes-animaux/${animalProfile.id}?tab=shopping&category=${category}`)
    scrollTop()
  }

  useEffect(() => {
    const category = queryParams.get('category')
    setSelectedCategory(category)
  }, [queryParams])

  const displaySelectedCategory = (category: string) => {
    switch (category) {
      case 'articles-et-conseils':
        return <BlogCategory animalProfile={animalProfile} />
      case 'Equipement et accessoires':
        return <AccessoriesCategory animalProfile={animalProfile} />
      case 'documents':
        return <AnimalDocumentsTab animal={animalProfile} />
      case 'recommandations':
        return <ShoppingCategory animalProfile={animalProfile} />
      case 'rendez-vous-suivi-sante':
        return <AnimalHealthOperationsTab animal={animalProfile} />
      case 'sante':
        return <AnimalHealthTab animalProfile={animalProfile} setAnimalProfile={setAnimalProfile} />
      default:
        setSelectedCategory(null)
        return null
    }
  }

  return selectedCategory ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 3 }}>
      {/* <Box>
        <IconButton onClick={() => navigate(`/mes-animaux/${animalProfile.id}/?tab=shopping`)}>
          <ArrowBackIos sx={{ fontSize: '16px', color: theme.palette.secondary.main }} />
        </IconButton>
      </Box> */}

      {displaySelectedCategory(selectedCategory)}

      {/* <BrandItem
        animalProfile={animalProfile}
        title="Assurance Santévet"
        description={`N’attendez pas que ${animalProfile.name} soit malade pour couvrir ses frais de santé`}
        logoUrl="https://images.ctfassets.net/denf86kkcx7r/7lMB7JNQYSAcvCSSelobJ/0e769132210d930340e56545d762937a/SanteVet-icon.svg"
        imgSrc="https://images.ctfassets.net/denf86kkcx7r/6xoUSPo69M26qlmjMjEFcH/de4bf01a87db0eb84d2ff58a9dee221a/ManDog_Visual_Green.png?fm=webp&w=1100"
        brandName="santevet"
      /> */}
      {/* <BrandItem
        animalProfile={animalProfile}
        title="Assurance Lovys"
        description={`Dès 60% de prise en charge\n
        jusqu’à 1500€ remboursés/an\n
        4 options`}
        logoUrl="https://i.imgur.com/TtL6XQz.png"
        imgSrc="https://www.lovys.com/fr/wp-content/uploads/sites/2/cache/2022/09/pets_newwebsite_large-1366x768-q80.png"
        brandName="lovys"
      /> */}
    </Box>
  ) : (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '16px' }}>
      {categories.map((category, index) => (
        <CategoryItem
          key={category.title + index}
          title={category.title}
          slug={category.slug}
          backgroundColor={category.backgroundColor}
          imgSrc={animalProfile.species === 'cat' ? category.catImgSrc : category.dogImgSrc}
          selectACategory={selectACategory}
          halfSize={category.halfSize}
          disabled={category.title === 'Santé' && animalProfile.species !== 'cat'}
        />
      ))}
    </Box>
  )
}
