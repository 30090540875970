import { Box } from '@mui/material'
import { FC } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import ErrorBoundary from '../ErrorBoundary'
import { useDashboardLoaderStore } from '../store/DashboardLoaderStore'
import { CircularProgressPanel } from './common/CircularProgressPanel'
import { ResponsiveAppBar } from './ResponsiveAppBar'

export const DashboardStructure: FC = () => {
  const { isLoading } = useDashboardLoaderStore()
  const location = useLocation()

  const stylesForBeRealPage = {
    height: '100vh',
    maxHeight: '100vh',
    mt: { xs: '-60px', md: '-64px' },
    overflowY: 'auto',
    ml: { xs: 0, md: '240px' },
  }

  const stylesForOtherPages = {
    maxHeight: { xs: 'calc(100% - 60px)', md: 'calc(100% - 64px)' },
    height: '100%',
    overflowY: 'auto',
    ml: { xs: 0, md: '240px' },
  }

  const styles =
    location.pathname.includes('actualites') && !location.pathname.includes('ajouter')
      ? stylesForBeRealPage
      : stylesForOtherPages

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          maxHeight: '100vh',
          overflowY: 'hidden',
        }}
      >
        <Box sx={{ position: 'relative', maxHeight: '100%', height: '100%', overflowY: 'hidden' }}>
          <ResponsiveAppBar />
          <Box sx={styles} className="scrollable-content">
            {isLoading && <CircularProgressPanel />}
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </Box>
        </Box>
      </Box>
    </>
  )
}
